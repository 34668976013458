export default class TigrisEvents {
    static OPEN_DIALOG = 'openDialog'
    static CLOSE_DIALOG = 'closeDialog'
    static DIALOG_CONTENT_LOADED = 'dialogContentLoaded'
    static DIALOG_FORM_SUBMIT = 'dialogFormSubmit'
    static AJAX_LOADED = 'ajaxLoaded'
    static AJAX_FORM_SENDED = 'form-ajax-sended'

    static WYSIWYG_LOADED = 'wysiwyg_loaded'

    static VUE_APP_MOUNTED = 'vue_app_mounted'

    static TOGGLE_THEME = 'toggleTheme'
}